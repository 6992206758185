var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"downBox"},[_c('div',{staticClass:"oneBox"},[_vm._m(0),_c('div',{staticClass:"btnlist"},[_vm._v(" 直播工具安装包 "),_c('div',{on:{"click":function($event){return _vm.downZip()}}},[_vm._v("点击此处下载")])])]),_c('div',{staticClass:"twoBox"},[_c('h4',{staticStyle:{"color":"#999999","margin-bottom":"10px"}},[_vm._v("步骤：")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('p',[_vm._v(" ④您已完成基础设置，接下来可通过本平台提供的服务器和密钥，在直播工具进行设置就可以进行直播了。"),_c('span',{staticStyle:{"color":"dodgerblue","cursor":"pointer"},on:{"click":function($event){return _vm.toUseDetail()}}},[_vm._v("具体查看使用指引")])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/images/playLogo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ① 点击上方【点击此处下载】，"),_c('span',[_vm._v("下载直播工具“ OBS Studio ”。")]),_vm._v("下载成功后，根据安装步骤"),_c('span',[_vm._v("进行安装。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox"},[_c('p',[_vm._v(" ②安装成功。在您的电脑找到“ OBS Studio ”. ")]),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/playLogo.png"),"alt":""}})]),_c('p',[_vm._v(" , "),_c('span',[_vm._v(" 启动“ OBS Studio ” ")]),_vm._v(" ,并允许获取麦克风权限等相关权限. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ③底部“来源”区域，"),_c('span',[_vm._v("设置显示来源")]),_vm._v("。单击“+”添加设备来源，"),_c('span',[_vm._v("添加“视频捕获设备”")]),_vm._v("获取摄像头、"),_c('span',[_vm._v("添加“显示器捕获”")]),_vm._v("获取全屏显示。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OneImg"},[_c('img',{attrs:{"src":require("../../assets/images/guide/down/3.1.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/images/guide/down/3.2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OneImg"},[_c('img',{attrs:{"src":require("../../assets/images/guide/down/4.png"),"alt":""}})])
}]

export { render, staticRenderFns }