<template>
  <div class="downBox">
    <div class="oneBox">
         <div>
             <img src="../../assets/images/playLogo.png" alt="">
         </div>
          <div class="btnlist">
               直播工具安装包
              <div @click="downZip()">点击此处下载</div>
          </div>
    </div>
    <!-- //文字 -->
    <div class="twoBox">
         <h4 style="color:#999999;margin-bottom:10px">步骤：</h4>
         <p>
          ① 点击上方【点击此处下载】，<span>下载直播工具“ OBS Studio ”。</span>下载成功后，根据安装步骤<span>进行安装。</span>
         </p>
         <div class="imgBox">
            <p>
               ②安装成功。在您的电脑找到“ OBS Studio ”.
            </p>
            <div>
                 <img src="../../assets/images/playLogo.png" alt="">
            </div>
            <p> 
                ,
                <span>
                  启动“ OBS Studio ”
                </span>
                ,并允许获取麦克风权限等相关权限.
            </p>
         </div>
         <!-- 序列三 -->
         <p>
           ③底部“来源”区域，<span>设置显示来源</span>。单击“+”添加设备来源，<span>添加“视频捕获设备”</span>获取摄像头、<span>添加“显示器捕获”</span>获取全屏显示。
         </p>
         <div class="OneImg">
            <img src="../../assets/images/guide/down/3.1.png" alt="">
             <img src="../../assets/images/guide/down/3.2.png" alt="">
         </div>


         <p>
           ④您已完成基础设置，接下来可通过本平台提供的服务器和密钥，在直播工具进行设置就可以进行直播了。<span style="color:dodgerblue;cursor: pointer;" @click="toUseDetail()">具体查看使用指引</span>
         </p>
         <div class="OneImg">
            <img src="../../assets/images/guide/down/4.png" alt="">
         </div>
    </div>
   


  </div>
</template>

<script>
export default {
  data(){
       return{
         
       }
  },
  methods:{
      //下载安装包
      downZip(){
         window.open("https://dckj.ks3-cn-guangzhou.ksyun.com/test/20210401/c7c5990f-2d72-4473-87ef-f1e92228a4a1.exe")
      },
      toUseDetail(){
          this.$emit("is_Use","2")
      }
  }
}
</script>
<style lang="less" scoped>
    .downBox{
        .oneBox{
             width: 400px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #FF8201;
            border-radius: 4px;
            margin-bottom: 5px;
            div{
                   
                    img{
                        width: 100%;
                    }
                }
            .btnlist{  
                display: flex;
                align-items: center;
                font-weight: bold;
                div{
                    background: #FF8201;
                    margin-left: 40px;
                    width: 120px;
                    height: 60px;
                    color: #fff;
                    cursor: pointer;
                    line-height: 60px;
                    text-align: center;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
        //文字
        .twoBox{
            font-size: 16px;
            p{
                span{
                    color:#FF8201;
                }
            }
            .imgBox{
                display: flex;
                align-items: center;
                
                div{
                    width: 50px;
                    height: 50px;
                    img{
                        width: 100%;
                    }
                }
            }
           .OneImg{
                  width: 500px;
                  margin:30px;
                  img{
                      width: 100%;
                      margin:20px 0;
                  }
            }
        }
    }
</style>